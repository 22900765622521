import React from "react"
import { Link } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"

const Footer = (props) => {
    return (
        <footer>
        <Container fluid>
          <Row>
              <Col>
              <Link to="/">Antarctic Continent</Link> > <span> {props.pageTitle}</span>
              </Col>
            <Col sm={3}>
            <small>© {new Date().getFullYear()}, 360&deg; World Atlas
        {` `}</small>
            </Col>
          </Row>
        </Container>
        </footer>
    )
}

export default Footer;