import React, { Component } from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { Container, Row, Col, Button } from "react-bootstrap"
import InfoBox from "../components/AntarcticPeninsula/InfoBox"
import SouthGeorgiaIsland from "../components/AntarcticPeninsula/SouthGeorigaIsland"
import AntarcticPeninsulaMap from "../components/AntarcticPeninsula/AntarcticPeninsulaMap"

class AntarcticPeninsula extends Component {
  constructor() {
    super()
    this.state = {
      name: "Info",
      showHideInfoBox: true,
      showHideSouthGeogia: false,
    }
    this.hideComponent = this.hideComponent.bind(this)
  }

  hideComponent(name) {
    console.log(name)
    switch (name) {
      case "showHideInfoBox":
        this.setState({
          showHideInfoBox: !this.state.showHideInfoBox,
          showHideSouthGeogia: false,
        })
        break
      case "showHideSouthGeorgia":
        this.setState({
          showHideSouthGeogia: !this.state.showHideSouthGeogia,
          showHideInfoBox: false,
        })
        break
    }
  }

  render() {
    const { showHideInfoBox, showHideSouthGeogia } = this.state

    return (
      <Layout pageTitle="Antarctic Peninsula &amp; South Georgia Island">
        <SEO title="Antarctic Peninsula &amp; South Georgia Island" />
        <Container fluid style={{
          display: 'flex',
          flexGrow: '1'
        }}>
        <Row  style={{
            width: '100%'
          }}>
          <Col sm={6}>
            {' '}
          {showHideInfoBox && <InfoBox />}
            {showHideSouthGeogia && <SouthGeorgiaIsland />}
          </Col>
          
          <Col sm={6} style={{
            display: 'flex',
            justifyContent: 'flex-end'
          }}>
              <div  style={{
            position: "relative",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end"
          }} >
                <AntarcticPeninsulaMap />
                  <div
                  style={{
                    position: "absolute",
                    top: "12%",
                    left: "22%",
                  }}
                    onClick={() => this.hideComponent("showHideSouthGeorgia")}
                    className="hotspot"
                  >
                    {" "}
                  </div>
                </div>

          </Col>
        </Row>
        </Container>
        {/* <div
          style={{
            padding: "0 15px 0 15px",
            width: "100%",
            minHeight: "0",
            display: "flex",
            flexFlow: "row wrap",
          }}
        >
          <div
            style={{
              flex: "0 0 50%",
              height: '100px'
            }}
          >
            {showHideInfoBox && <InfoBox />}
            {showHideSouthGeogia && <SouthGeorgiaIsland />}
          </div>

          <div
            style={{
              flex: "0 0 50%",
              paddingRight: "15px",
            }}
          >
            <div>
              <div
                style={{
                  position: "relative",
                  display: "flex",
                  minHeight: "0",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                }}
              >
                <div
                  style={{
                    width: "75%",
                    height: "85vh",
                  }}
                >
                  <img src={AntarcticPeninsulaMap} />
                </div>

                <div
                  style={{
                    position: "absolute",
                    top: "0",
                    right: "0",
                  }}
                >
                  <div
                    onClick={() => this.hideComponent("showHideSouthGeorgia")}
                    className="hotspot"
                  >
                    {" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div> */}
          {/* <Row className="mb-4">
              <Col>
                <Row>
                  <Col>
                    
                    <Button
                      onClick={() => this.hideComponent("showHideInfoBox")}
                    >
                      Info Box
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      onClick={() => this.hideComponent("showHideSouthGeorgia")}
                    >
                      South Georgia
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row> */}
        

      </Layout>
    )
  }
}

export default AntarcticPeninsula
