import React from "react"
import PropTypes from "prop-types"
import "bootstrap/dist/css/bootstrap.min.css"
import { useStaticQuery, graphql } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"

import Header from "./header"
import Footer from "./Footer"
import "./layout.css"

const Layout = ({ children, pageTitle }) => {
  // const data = useStaticQuery(graphql`
  //   query SiteTitleQuery {
  //     site {
  //       siteMetadata {
  //         title
  //       }
  //     }
  //   }
  // `)

  return (
    <>
      <div className="wrapper" >
        <Header pageTitle={pageTitle} />
          <main style={{
            
            flexGrow: `1`,
            flexShrink: `0`,
            display: `flex`
          }}>{children}</main>
      
      <Footer pageTitle={pageTitle} />
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
