import React from "react"
import { Link } from 'gatsby'
import { Container, Row, Col } from "react-bootstrap"
import styled from "styled-components"
import AntarcticaSVGTest from "./AntarcticaSVG"

const Map = styled.div`
  position: relative;
`

const onClick = ({ target }) => {
    const name = target.attributes.name.value;
    window.location.replace(`${name}`)
}

const peninsulaOver = () => {
    document.getElementById('antarctic_peninsula').setAttribute("opacity", "0.5")
}
const peninsulaOut = () => {
    document.getElementById('antarctic_peninsula').setAttribute("opacity", "1")
}

const southPoleOver = () => {
    document.getElementById('south_pole').setAttribute("opacity", "0.5")
}
const southPoleOut = () => {
    document.getElementById('south_pole').setAttribute("opacity", "1")
}

const easternOver = () => {
    document.getElementById('eastern_antarctica').setAttribute("opacity", "0.5")
}
const easternOut = () => {
    document.getElementById('eastern_antarctica').setAttribute("opacity", "1")
}

const rossOver = () => {
    document.getElementById('ross_sea_region').setAttribute("opacity", "0.5")
}
const rossOut = () => {
    document.getElementById('ross_sea_region').setAttribute("opacity", "1")
}

const southernOver = () => {
    document.getElementById('southern_ocean').setAttribute("opacity", "0.5")
}
const southernOut = () => {
    document.getElementById('southern_ocean').setAttribute("opacity", "1")
}

const AntarcticaMap = () => {
// const [isShown, setIsShown] = useState(false);

  return (
    <Container fluid>
      <Row>
        <Col style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center'
            
        }}>
          <Map>
            <div className="map-label"
              style={{
                top: "8%",
                left: "6%",
              }}
            onMouseEnter={() => peninsulaOver()}
            onMouseLeave={() => peninsulaOut()}
            >
               <Link to="/antarctic-peninsula" className="map-link">Antarctic Peninsula &amp;<br/>South Georgia Island</Link>
               {/* {isShown && (
               document.getElementById('antarctic_peninsula').setAttribute("opacity", "0.5")
           )} */}
            </div>
            <div className="map-label"
             style={{
                top: '49%',
                left: '46%',
            }}
            onMouseEnter={() => southPoleOver()}
            onMouseLeave={() => southPoleOut()}
            >
                <Link to="/south-pole" className="map-link">South<br/>Pole</Link>
            </div>
            <div className="map-label"
            style={{
                top: '48%',
                left: '75%',
            }}
            onMouseEnter={() => easternOver()}
            onMouseLeave={() => easternOut()}
            >
                <Link to="/eastern-antarctica" className="map-link">Eastern Antarctica</Link>
            </div>

            <div className="map-label" style={{
                top: '68%',
                left: '45%',
            }}
            onMouseEnter={() => rossOver()}
            onMouseLeave={() => rossOut()}
            >
                <Link to="/ross-sea-region" className="map-link">Ross<br/>Sea<br/>Region</Link>
            </div>

            <div className="map-label"
            style={{
                top: '86%',
                left: '32%',
            }}
            onMouseEnter={() => southernOver()}
            onMouseLeave={() => southernOut()}
            >
                <Link to="/southern-ocean" className="map-link">Southern Ocean</Link>
            </div>
            <AntarcticaSVGTest layerProps={{ onClick }} />
          </Map>
        </Col>
      </Row>
    </Container>
  )
}

export default AntarcticaMap
