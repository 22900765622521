import React, { Component } from "react"

import { Card } from "react-bootstrap"

class InfoBox extends Component {
    constructor() {
        super();
        this.state = {
            name: "Info"
        };
    }

    render() {
        return (
        <Card>
            <Card.Header>
            ANTARCTIC PENINSULA
            </Card.Header>
            <Card.Body>
              <Card.Text>
              The peninsula is a wonderous place, teeming with wildlife unafraid of man. We encounter remnants of volcanic destruction and view remarkable natural wonders. This is the Antarctic we dream about.
              </Card.Text>
            </Card.Body>
          </Card>
        )
    }
}

export default InfoBox