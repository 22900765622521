import React from "react"
import styled from "styled-components"

const SVGMap = styled.svg `
    height: 75vh;   
	.st0{fill:#00AFD2;stroke:#FFFFFF;stroke-width:1.2869;}
	.st1{fill:none;stroke:#00859F;stroke-width:0.3088;stroke-linecap:round;stroke-linejoin:round;}
	.st2{fill:#B4D4E5;stroke:#FFFFFF;stroke-width:1.2869;}
	
		.st3{fill:#B4D4E5;stroke:#FFFFFF;stroke-width:0.3217;stroke-linecap:round;stroke-linejoin:round;stroke-dasharray:0.6435,0.6435;}
	.st4{fill:none;stroke:#FFFFFF;stroke-width:1.2869;stroke-dasharray:5.1476,7.7214;}
	.st5{fill:#FFFFFF;}
	.st6{fill:#0091C7;}
`

const AntarcticPeninsulaMap = () => {
    return (
        <div style={{
           
        }}>
        <SVGMap version="1.1" id="Antarctic_Peninsula" viewBox="0 0 342.7 644.7">
<path className="st0" d="M6.5,239.9c0.9,0.3,1.3,1,1.9,1.7c0.7,0.6,1.4,1.3,2.1,2c0.7,0.9,1.1,2,1,3.1c-1.2,0.2-1.8-1-2.3-1.8
	c-0.5-0.9-1.4-1.5-2.3-1.9c-0.3-1-0.4-2-0.6-3c0.2,0,0.1-0.1,0.2-0.2"/>
<polygon className="st1" points="77.8,268.5 79,271.9 78.8,270.7 "/>
<polygon className="st1" points="43.9,236.8 44.6,239.1 44.6,238.6 "/>
<polygon className="st1" points="76.9,307.9 76.2,309 78.1,306.9 "/>
<path className="st1" d="M79.2,36.1l3-2.8l6.4-0.1l0.4,0.4l-3.7,1.5L83,37.5l-2.5-0.4l-1.3,2.4L78,40.2l-0.4-0.2l-0.2-1L79.2,36.1z
	 M64,207.2l1.7,5.8l-2.1,1.4l0,0.2l-0.6,0.3l0.6-0.4L64,207.2z M48.9,210.9l3-3.5l5.2-1.2l1.8,1.7v2.9l1.4,1.6l-2.5,7l-0.9,0.9
	l-1.6,0.6l-0.1-1.8l-0.8-0.8l-1.5-0.6l-1.6-0.2l-1.6,2.1L48,218v-1.4l-2-1.8l0.8-5.5L48.9,210.9z M8.5,220.2l-1.2,1.2l-2.5,1.3
	l-2.4,6.8L2,229.4l0.4-0.1L2.3,229H1.7l-0.3-1.1l0.3-1.8L1.1,224l3.4-8.1l4-0.3l0.3,2.7L8.5,220.2z M9.4,190.6l-0.1,2.2l-0.9,0.6
	L8.3,196l-0.5,1.3l-1.4,0.6l-0.9,1.5l-0.3,0.3l-0.6-1.1L4,193.9l2.8-10.1l1.3-1.6l2,0.3l-1.6,6L9.4,190.6z M11.6,144.4l-0.1,3.1
	l-1.6,1l-3.2-0.8v-1.9l4.6-7.3l1.4-1.1h0.6l0.4,0.4l0.2,0.8L11.6,144.4z M32.3,282.8l-0.8,1.8l-3.4,1.8l-0.2-1.2l-1.8-1.6l-0.2-4.9
	l-1-2.4l-1.8-2l0.1-1.8l0.7-1.6l1.5,0.4l1,2.1l1.1-2.2l1.1-0.1l1.1,2.2l1.5,0.4l0.2-3.6l1.1-0.5l-0.3,3.6l0.8,2.7L32.3,282.8z
	 M32.3,267.4l0-0.5l-3-1.5l-0.8-2.8l-2.3-0.3l-1.6-0.9v-2.6l0.6-1l2.1-1.1H28l1.6,1.1l2.9,5.2l-0.2,3.9l0.5,0.3L32.3,267.4z
	 M45.7,319.2l-1.5,0.1l-0.2-1.5l-4.3-5.3l0.4-2.6l1.2-0.2l2.4,0.4l-0.2,1.9l2.3,3.7l0.5,1.9L45.7,319.2z M69.8,586.8l-2,0.9
	l-3.8-1.8l-0.4-1.2l0.4-1.4L67,582l3.8,1.1L69.8,586.8z M78.7,365l-0.5,0.7l0.2-0.5l-0.7,0.5l-1.4-0.1l-2.2,1l-2.2-0.4l-3.3-2.4
	l-9.9-13.9l0.5-2.3l-0.4-2.4l0.4-1.4l4.1,3.8l2.4,1.2l3,0.1l1.5,0.9l0.4,1.9l1.6,2.9l1.4-0.6l1.1-1.5l3.3,3.7l-0.8,2.6l2,4.1
	l-0.9,2.2L78.7,365z M152.5,426.2l-5.6,5l-1.1-2.2l0.7-5.4l4.5-5.4l-0.6-3l-2.4-1.5l1.7-2l1.9,0.2l2.2-2l-4.3-0.9l-3,0.9l-2.2-0.4
	l-5.6,4.5v2.6l-4.5,3.9l-1.7-0.6l-2.4-2.8l0.6-3.3l-1.7-1.1l0.7-2l-2-2.8l-3.7,2.2l-5.2-0.7l-2.6-3l-3.5,0.6l-2.8-2.2l-4.6,3.2
	l-1.7-0.6l-3.5,0.4l-2-1.9l-3-9.1l0.7-4.8l-0.7-4.1l2.2-2.2l3-0.6l8,2.4l1.3-0.7l0.9-1.5l25,2l4.6-1.7L179,396l1.7,3.3l5.6,2v2.8
	l1.5,3.9l4.1,2l-0.4,11.7l-2,1.9l0.4,1.8L187,432v4.8l-8.4,4.3l-2.2-1.7l-0.2-5.2l5.6-7.2l0.9-5.8l-2.8-3l-1.1,0.4l0.7,2.2l-0.7,4.5
	l-3.9,4.5l-1.3-0.7l0.9-8.9l-1.9-0.2l-1.5,3.5l-6.9,3.3l-2.4,0.2l-0.9,1.1l5.4,6.3l-5,7.6l6.3-0.6l2,3.2l-0.4,3l-4.5,1.9l0.9,1.1
	l-4.1,4.1l-3.5,0.6l-0.9-1.9l-3.2,1.3l-0.2-1.7l-1.7-2l0.4-1.9l2.8-0.9l1.3-3.2l-6.3-0.7l0.2-2.6l2.4-2.4l2.2,0.4l1.7-2.8l-2-0.2
	l-2,0.6h-2.8l-0.4-3l4.6-4.1L152.5,426.2z"/>
<polygon className="st1" points="45.8,246 46.4,248.1 45.8,244.3 "/>
<polygon className="st0" points="13.3,137.4 12.7,137.4 11.3,138.5 6.7,145.8 6.7,147.7 9.9,148.5 11.5,147.5 11.6,144.4 13.9,138.6 
	13.7,137.8 "/>
<polygon className="st0" points="8.1,182.3 6.8,183.9 4,193.9 4.5,198.6 5.1,199.7 5.5,199.4 6.4,197.9 7.8,197.3 8.3,196 8.5,193.4 
	9.3,192.8 9.4,190.6 8.5,188.6 10.1,182.6 "/>
<polygon className="st0" points="4.5,215.9 1.1,224 1.6,226.1 1.4,227.9 1.7,229 2.3,229 2.4,229.3 2,229.4 2.3,229.6 4.7,222.7 
	7.2,221.4 8.5,220.2 8.8,218.4 8.5,215.7 "/>
<polygon className="st0" points="48,216.6 48,218 49.8,219.5 51.3,217.4 52.9,217.6 54.4,218.2 55.2,219 55.3,220.8 56.9,220.2 
	57.7,219.3 60.2,212.3 58.8,210.8 58.8,207.9 57,206.2 51.9,207.4 48.9,210.9 46.8,209.3 46,214.8 "/>
<polygon className="st0" points="32.3,267.4 32.8,267.1 32.3,266.9 "/>
<polygon className="st0" points="29.5,257.8 28,256.7 27.4,256.7 25.3,257.8 24.6,258.8 24.6,261.4 26.2,262.3 28.5,262.6 29.3,265.4 
	32.3,266.9 32.4,263 "/>
<polygon className="st0" points="32.3,269.6 31.3,270.1 31.1,273.7 29.6,273.3 28.6,271.2 27.4,271.2 26.4,273.4 25.4,271.3 23.9,271 
	23.2,272.6 23.2,274.3 25,276.3 26,278.7 26.1,283.6 27.9,285.2 28.1,286.4 31.5,284.6 32.3,282.8 32.8,275.9 32,273.2 "/>
<polygon className="st0" points="43.5,312.1 43.6,310.2 41.3,309.8 40.1,309.9 39.7,312.6 44,317.8 44.2,319.3 45.7,319.2 46.3,317.7 
	45.7,315.8 "/>
<polygon className="st0" points="77.3,358.9 78.1,356.2 74.8,352.6 73.7,354 72.3,354.7 70.7,351.8 70.2,349.8 68.8,349 65.8,348.9 
	63.3,347.7 59.2,343.8 58.9,345.2 59.2,347.6 58.7,349.8 68.6,363.8 71.9,366.2 74.1,366.7 76.3,365.6 77.7,365.7 78.4,365.2 
	79.4,363 "/>
<polygon className="st0" points="78.7,365 78.4,365.2 78.2,365.7 "/>
<polygon className="st0" points="64,583.3 63.6,584.7 64,585.9 67.8,587.7 69.8,586.8 70.8,583 67,582 "/>
<polygon className="st0" points="63.7,214.5 63.7,214.4 63.1,214.8 "/>
<polygon className="st0" points="64,207.2 63.7,214.4 65.8,212.9 "/>
<polygon className="st0" points="342,535 341,535.2 339.5,537.1 340.4,539.2 339.2,540.4 316.8,546.2 315.9,542.6 316.6,539.7 
	320.5,538.7 331.8,529.8 333.9,520.4 331.1,512.7 328.2,510.8 324.6,511 319.7,513.5 309.6,527.2 306.2,529.8 307.4,525 
	302.1,528.4 299.7,528.7 299.8,524.6 302.9,522.4 311.6,509.6 312.3,505.1 315.6,500 319.7,496.3 321.2,493 320.9,489.9 
	316.1,482.9 311.1,479.2 308.9,479.8 303.8,487.9 304.1,493.2 289.7,499.3 285.8,499.7 281.5,501.6 276.1,501.9 272.1,504.3 
	269.6,504.3 268.4,503.6 266.7,503.8 264.1,502.8 261.7,497.6 259.3,497.6 259.1,496.6 263.3,494.2 263.9,489.9 265.5,489.1 
	271.5,489.8 274.9,489.2 277.4,487.7 279.8,484.8 281,480 283.6,477.6 289.9,461.4 292.3,458.1 292,454.9 285.5,450.4 284.1,446.3 
	285.5,438.8 284.1,436.3 285.3,425.3 284.1,422.7 283.9,418.7 282.5,416.9 277.9,397.7 277.2,397.6 274.8,402.2 274.5,405 
	273,405.8 271.4,402.9 273,395.1 269.8,394.9 267.3,399.4 266.8,399 266.8,396.2 265.9,395.1 266.8,394.1 267.5,392 259.7,389.9 
	266,387.8 268.6,389.3 271.4,389.7 271.7,386.6 269.8,383.6 271.8,382.9 266.3,374.4 265,370.1 262.2,369.3 257.6,369.6 
	259.3,366.8 254.5,366.6 243.5,357.6 245.8,347.7 236.8,346 232.8,347.4 233.4,348.9 231.5,350.2 232.7,352.4 230.4,350.7 
	229.4,350.6 227.5,348.8 224.1,347.4 223.1,347.6 222,349.3 222.6,352.7 217.5,351.1 216.4,351.5 216,351.4 215,351.4 215,350.5 
	217.1,348.7 217.2,346.2 216,345.1 215.1,346 214.9,345.4 213.8,345.2 214.4,343.9 213.2,343.6 210.9,344.4 210.6,342.1 
	207.9,341.4 208.4,343.1 207.4,345 209.3,346.2 208.7,346.7 207.1,346.8 205.5,347.7 203.6,352.6 202.3,354.1 202,353.2 
	202.4,351.9 202.1,351.3 201,352.7 199.7,352.9 199.5,352.2 203.6,341.2 202,339.7 202,338.4 201.2,337.5 195.8,338.3 196.7,343.4 
	196.3,344.4 195.1,344.4 193.4,339.4 192.6,338.7 193.6,336.9 193.7,335.5 191.4,336 191,335.1 193.1,333.7 190.8,333 187.9,333.4 
	188.6,331 187.2,330.8 183.2,331.3 178.6,330.1 177.9,331.3 176.5,332.2 176.7,333.6 178.6,335.2 176.4,336.6 174.3,335 
	172.8,335.6 172.6,334.6 173.5,333.9 173.6,331.2 171.8,330.7 172.8,329.7 171.3,327.2 169.7,329.7 169,332.9 167.9,333.3 
	165.4,333.2 164.3,333.4 163.1,333.3 162.6,332 161.6,332.3 160.6,332 160.8,331.4 161.9,330.8 162.3,330.1 163.1,329.5 
	163.5,328.2 162.6,327.2 160.8,327.7 159.1,326.8 151,328.5 150.8,327.1 142.5,322.5 141.9,323.2 140.8,323 138.7,324.9 137.6,327 
	135.5,325.8 132.5,325.5 132.1,326.2 133.1,328.5 132.6,329.9 131.6,329.6 130.7,326.8 129.5,325.5 128.4,325.5 127.5,326.7 
	125,326.5 125.7,324.7 125.2,324.8 123.7,325.7 121.9,325.7 121.9,324.7 120.9,324.8 121.6,323.7 124.8,321.5 124.3,321 
	119.4,323.2 116.8,325.7 114.8,324.9 114,322 114.5,317.3 116.2,315.3 117.4,315.2 118.3,314.7 117.7,312.3 115,314.2 112.6,319.1 
	111.9,325.6 112.5,328.5 114.4,328.4 113.8,329.6 118.7,327.2 119.7,327.6 117.3,329.1 122.4,326.8 122.6,327.5 116,331.4 
	117.2,332.1 116.6,334.5 115.9,334.6 115.9,335.9 113.2,339.1 108.7,339.3 108.3,338.9 108.9,338 109.1,335.9 108.5,335.3 
	108.9,334.7 108.1,334.3 107,334.7 105.1,337.8 103.7,337.9 103.1,336.2 103.1,334.3 102.3,331.8 102.7,331.4 103.1,330 
	102.9,328.7 101.9,327.8 100.6,327.5 99.8,328.4 98.7,328.6 99.6,329.5 100.6,331.9 100.6,333.3 100.2,333.2 99.1,335 99,336.1 
	98.6,336.9 97.6,336.8 96.7,335.2 96.7,334.3 96.5,333.8 96.1,331.5 95.5,331.4 94.9,332.2 93.3,332.9 92.5,333 92.2,331.5 
	90.5,331.5 90,331.2 89.7,330.4 89.7,329.2 89.4,328.3 89.4,327.3 88.7,328 88.2,328.3 87.3,328 87,328.8 85.3,329.7 83.7,328.4 
	82.9,326.9 81.8,326.9 81.1,325.9 80.8,324.6 81.3,324.4 82.2,324.9 82.5,324.5 81.7,322.6 83.1,321.8 82.6,320.3 83.3,319.8 
	83.1,318.8 81.9,318.3 81.3,318.5 80.7,319.3 80.2,319.2 80.1,316.2 78.5,315.8 78.4,316.4 77.8,317.3 76.8,316.7 76.3,317.2 
	75.4,317.3 75,315.9 74.3,314.9 75.2,314.4 74.4,313.6 74.3,312 75.3,310 76.2,309 76.9,307.9 78.1,306.9 78.6,305.4 78,303.8 
	77.1,303.5 75.4,303.4 73.6,306.8 72.3,306.7 71.8,305.5 71.4,305.6 69.5,304.7 69.3,303.9 69.5,303.2 70.4,303 70.8,302.3 
	71,301.5 69.7,300.8 69.2,301.6 68.4,302.1 67.6,302 68,299.8 66.9,300.2 66.7,299.7 67.5,297.6 67.4,297 66.8,297 66,298.7 
	65.4,298.4 64.8,298.4 64.2,298.2 63.4,295.8 63.4,295 63.1,294.4 62.7,294.1 63.2,293.8 63.6,294.2 64.2,294.4 64.8,294 
	65.4,293.9 66,293.2 67.8,292 68.8,292.4 74.4,291.4 78.7,292.3 79.6,292 80.4,291.1 80.4,289.9 80,289.1 79.5,289 79,290.1 
	78.6,290.2 77.6,289.2 76.8,290 76.2,290.3 75.6,289.9 74.7,287.9 74.6,286.9 74.3,286.4 68.8,289 68.4,288.7 68,286.7 69.1,284.6 
	68.8,283.5 70.1,280.8 71.5,279.9 74.7,279.5 77.4,280.2 79,279.8 78.9,279.2 78.3,278.6 77.3,278.5 76.7,277.8 74.4,276.8 
	74.3,275.8 74.8,275.3 74.8,274.9 72.9,273.1 73,272.5 73.7,272.5 75.2,272 76.5,272 78.7,272.6 79,271.9 77.8,268.5 77.1,268.1 
	76.2,267.3 76.3,266.3 76.1,265.4 73.6,262.2 72.9,262.6 72.3,263.6 72,264.9 71.1,265.3 70.5,265.8 70.4,266.2 72.1,266.7 
	72.8,267.1 73.3,267.9 73.3,268.6 69.2,268.4 68.9,269.5 69.5,270.3 70.7,270.2 71.2,270.8 71.9,271.1 72,271.8 70.7,272.9 
	69.8,273.2 70.8,274.7 70.7,275.5 71.4,275.6 71.3,277.8 70.5,277.9 69.8,278.7 68.9,278.8 67.3,278.5 66.5,278.7 65.7,279.4 
	65.2,280.4 64.7,280.5 63.8,280.3 63.3,280.4 62.5,280.3 59.6,277.9 59.7,275.9 58.3,273.7 57.6,273.8 57.2,273.2 56.9,272.1 
	58.5,269.4 58.7,268.6 58.4,268.2 57.9,268.2 56.4,270.4 54.5,271.3 53.7,271.2 52.5,270 51.9,270.2 51.2,270.1 50.6,269.7 
	50.4,268.8 49.1,267.2 48.9,266.6 49.3,266.1 50.7,265.8 51.4,265.4 51.8,264.3 51.7,263.8 50.2,262.9 49,260.7 48.9,259.8 
	49.3,259 49.3,258.2 49,257.2 49.7,255.9 50.3,255.4 50.2,254.7 49.8,254.5 49.5,254.9 49.1,255.1 48,250 46.4,248.1 45.8,246 
	45.8,244.3 45.4,243.7 45,243.4 45,242.6 45.3,242 45.3,241.3 45,240.8 44.7,240.8 44.6,239.1 43.9,236.8 44.2,234.7 44.8,233.9 
	45.8,234.5 46.6,234.7 46.8,235.2 47.4,235.5 48.9,234.4 49.2,234 48.9,233.4 48.4,233 48,233 47.6,233.1 44.6,232.4 44.3,231.7 
	44.4,230.9 44.9,230.2 45.4,230.4 46.9,229.9 47.4,230.2 48.2,230.1 49.5,229.5 49.7,229 49.7,227.6 50.1,227.2 50.6,227.7 
	50.9,228.2 51.3,228.4 52.9,227.3 51.7,226.9 42.5,213.8 42.5,206.8 43.3,205.2 42.1,203.2 41.9,200.3 42.9,198.8 44.9,198.6 
	46.4,199.2 46.7,196.9 44.3,194 41.5,193.3 39.7,194.1 33.7,215 35.7,219.1 34.6,221 36.2,227 34.3,228.9 33.5,242.7 37.5,248.7 
	36.8,253.9 38.8,255 38.7,264.7 37.9,265.7 35.9,264.7 36.8,266.8 39.8,269.1 41.3,268.2 39.5,272.4 40.7,277.5 41.4,278.1 
	44.1,276.2 44.1,278.7 41.7,280.2 39.4,285.5 39.2,288.7 41.5,290.6 47,291.5 49.4,289.5 51,289.4 49.2,293.4 49.2,294.6 
	51.2,294.7 48.6,298.5 48.8,299.3 52.2,298.1 51.9,301.6 52.8,302.1 55.8,300.9 57.3,301.7 56.3,304 54.5,306 53.1,306.6 
	52.7,308.2 52.7,307.9 54.8,309.1 54.5,310.6 55.7,313.7 55.6,315.6 57,318 59.2,319.3 65.3,317.8 67.9,321 68.1,326.7 66.9,329.5 
	77.6,334.2 78.3,335.9 75.8,339.1 68.1,336.5 67.4,338.2 68.2,341 71.1,345.5 73.2,346.4 74.4,347.7 74.6,348.5 75.9,348 
	79.6,348.9 82.5,343.7 82.8,341 83.4,339.9 85,340.6 86.4,343.9 87,344.6 87.6,344.9 88.7,343.1 93.4,350.1 95.3,350.6 99,348 
	98.3,352.5 101.7,351.4 103.5,353.7 109.7,356.6 111.8,361.8 115,363.1 116.5,365.4 114.2,370.2 114.3,372.8 117.3,372.3 
	119.7,374.4 118.7,377.7 134.3,380.6 139.3,378.7 163.6,382.5 169.2,386 176.6,386.9 177.7,386 182.5,385.4 184.7,387.5 
	195.1,390.4 203.3,400.8 207,425.3 204.8,429.8 205.3,444 205.9,444.8 205.6,444.8 206.5,444.9 206.4,446.8 201,447.5 199.1,449.2 
	198.6,451.6 196,451.5 193.6,449.6 191,450.8 189.8,453.8 187.8,455.7 186.2,459.1 185.9,465 188.8,465.7 182.4,467 179.1,465.7 
	177.9,467.8 178.5,468.8 177.3,469.9 175.1,469.3 174.5,470.6 174.9,471.3 173.5,473.2 172.6,477.6 172.8,480.4 174.6,480.4 
	177.9,477.6 179.2,479.7 179.3,487.6 183.5,488 192.3,484.5 195.9,485 194.4,487.6 184,490.7 181.6,494.4 182.6,499.1 171.4,544.5 
	164.5,550.6 161.7,556.3 160,581.1 166.7,598.3 165.5,612 167.9,618.7 167.2,619.6 167.7,621 168.8,621.8 168.3,623.8 166.2,627.2 
	163.6,629.1 161.9,625.7 160.1,626.5 161.1,629.4 159.6,630.2 157.2,627.9 155,628.4 155.3,631.8 156.7,633.7 153.1,634.5 
	150,634.2 153.1,637.3 156.4,638.1 155.6,640.2 153.6,640.8 154.3,642.1 158.6,641.8 157.8,644 342,644 "/>
<polygon className="st0" points="150.1,437.2 152.8,437.2 154.9,436.6 156.9,436.8 155.2,439.6 153,439.2 150.6,441.6 150.4,444.2 
	156.7,445 155.4,448.1 152.6,449.1 152.3,450.9 153.9,453 154.1,454.6 157.3,453.3 158.2,455.2 161.7,454.6 165.8,450.5 
	164.9,449.4 169.3,447.6 169.7,444.6 167.7,441.5 161.4,442 166.4,434.4 161,428.1 161.9,427 164.3,426.8 171.2,423.5 172.7,419.9 
	174.5,420.1 173.6,429 174.9,429.8 178.8,425.3 179.6,420.9 178.8,418.6 179.9,418.3 182.7,421.2 181.8,427 176.2,434.2 
	176.4,439.4 178.6,441.1 187,436.8 187,432 189.8,425.5 189.4,423.6 191.4,421.8 191.8,410.1 187.7,408.1 186.2,404.2 186.2,401.4 
	180.7,399.3 179,396 140.2,385.6 135.6,387.3 110.5,385.2 109.6,386.7 108.3,387.5 100.3,385 97.4,385.6 95.1,387.8 95.9,391.9 
	95.1,396.7 98.1,405.8 100.1,407.7 103.7,407.3 105.3,407.9 110,404.7 112.8,406.9 116.3,406.4 118.9,409.4 124.1,410.1 
	127.8,407.9 129.8,410.7 129.1,412.7 130.8,413.8 130.2,417.2 132.6,419.9 134.3,420.5 138.7,416.6 138.7,414 144.3,409.5 
	146.5,409.9 149.5,409 153.8,409.9 151.5,412 149.7,411.8 148,413.8 150.4,415.3 151,418.3 146.5,423.6 145.8,429 146.9,431.2 
	152.5,426.2 154.3,430.1 149.7,434.2 "/>
<polyline className="st2" points="84.7,272.4 76.6,264.9 75.4,262.8 67.2,256.2 64.1,248.7 63.5,243.7 61.2,240.2 56.6,239.8 
	54.3,235.4 52.9,227.3 51.3,228.4 50.9,228.2 50.6,227.7 50.1,227.2 49.7,227.6 49.7,229 49.5,229.5 48.2,230.1 47.4,230.2 
	46.9,229.9 45.4,230.4 44.9,230.2 44.4,230.9 44.3,231.7 44.6,232.4 47.6,233.1 48,233 48.4,233 48.9,233.4 49.2,234 48.9,234.4 
	47.4,235.5 46.8,235.2 46.6,234.7 45.8,234.5 44.8,233.9 44.2,234.7 43.9,236.8 44.6,238.6 44.6,239.1 44.7,239.3 44.7,240.8 
	45,240.8 45.3,241.3 45.3,242 45,242.6 45,243.4 45.4,243.7 45.8,244.3 46.4,248.1 48,250 49.1,255.1 49.5,254.9 49.8,254.5 
	50.2,254.7 50.3,255.4 49.7,255.9 49,257.2 49.3,258.2 49.3,259 48.9,259.8 49,260.7 50.2,262.9 51.7,263.8 51.8,264.3 51.4,265.4 
	50.7,265.8 49.3,266.1 48.9,266.6 49.1,267.2 50.4,268.8 50.6,269.7 51.2,270.1 51.9,270.2 52.5,270 53.7,271.2 54.5,271.3 
	56.4,270.4 57.9,268.2 58.4,268.2 58.7,268.6 58.5,269.4 56.9,272.1 57.2,273.2 57.6,273.8 58.3,273.7 59.7,275.9 59.6,277.9 
	62.5,280.3 63.3,280.4 63.8,280.3 64.7,280.5 65.2,280.4 65.7,279.4 66.5,278.7 67.3,278.5 68.9,278.8 69.8,278.7 70.5,277.9 
	71.3,277.8 71.4,275.6 70.7,275.5 70.8,274.7 69.8,273.2 70.7,272.9 72,271.8 71.9,271.1 71.2,270.8 70.7,270.2 69.5,270.3 
	68.9,269.5 69.2,268.4 73.3,268.6 73.3,267.9 72.8,267.1 72.1,266.7 70.4,266.2 70.5,265.8 71.1,265.3 72,264.9 72.3,263.6 
	72.9,262.6 73.6,262.2 76.1,265.4 76.3,266.3 76.2,267.3 77.1,268.1 77.8,268.5 78.8,270.7 79,271.9 78.7,272.6 76.5,272 75.2,272 
	73.7,272.5 73,272.5 72.9,273.1 74.8,274.9 74.8,275.3 74.3,275.8 74.4,276.8 76.7,277.8 77.3,278.5 78.3,278.6 78.9,279.2 
	79,279.8 77.4,280.2 74.7,279.5 71.5,279.9 70.1,280.8 68.8,283.5 69.1,284.6 68,286.7 68.4,288.7 68.8,289 74.3,286.4 74.6,286.9 
	74.7,287.9 75.6,289.9 76.2,290.3 76.8,290 77.6,289.2 78.6,290.2 79,290.1 79.5,289 80,289.1 80.4,289.9 80.4,291.1 79.6,292 
	78.7,292.3 74.4,291.4 68.8,292.4 67.8,292 66,293.2 65.4,293.9 64.8,294 64.2,294.4 63.6,294.2 63.2,293.8 62.7,294.1 63.1,294.4 
	63.4,295 63.4,295.8 64.2,298.2 64.8,298.4 65.4,298.4 66,298.7 66.8,297 67.4,297 67.5,297.6 66.7,299.7 66.9,300.2 68,299.8 
	67.6,302 68.4,302.1 69.2,301.6 69.7,300.8 71,301.5 70.8,302.3 70.4,303 69.5,303.2 69.3,303.9 69.5,304.7 71.4,305.6 71.8,305.5 
	72.3,306.7 73.6,306.8 75.4,303.4 77.1,303.5 78,303.8 78.6,305.4 78.1,306.9 76.2,309 74.3,312 74.4,313.6 75.2,314.4 74.3,314.9 
	75,315.9 75.4,317.3 76.3,317.2 76.8,316.7 77.8,317.3 78.4,316.4 78.5,315.8 80.1,316.2 80.2,319.2 80.7,319.3 81.3,318.5 
	81.9,318.3 83.1,318.8 83.3,319.8 82.6,320.3 83.1,321.8 81.7,322.6 82.5,324.5 82.2,324.9 81.3,324.4 80.8,324.6 81.1,325.9 
	81.8,326.9 82.9,326.9 83.7,328.4 85.3,329.7 87,328.8 87.3,328 88.2,328.3 88.7,328 89.4,327.3 89.4,328.3 89.7,329.2 89.7,330.4 
	90,331.2 90.5,331.5 92.2,331.5 92.5,333 93.3,332.9 94.9,332.2 95.5,331.4 96.1,331.5 96.5,333.8 96.7,334.3 96.7,335.2 
	97.6,336.8 98.6,336.9 99,336.1 99.1,335 100.2,333.2 100.6,333.3 100.6,331.9 99.6,329.5 98.7,328.6 99.8,328.4 100.6,327.5 
	101.9,327.8 102.9,328.7 103.1,330 102.7,331.4 102.3,331.8 103.1,334.3 103.1,336.2 103.7,337.9 105.1,337.8 107,334.7 
	108.1,334.3 108.9,334.7 108.5,335.3 109.1,335.9 108.9,338 108.3,338.9 108.7,339.3 113.2,339.1 115.9,335.9 115.9,334.6 
	116.6,334.5 117.2,332.1 116,331.4 122.6,327.5 122.4,326.8 117.3,329.1 119.7,327.6 118.7,327.2 113.8,329.6 114.4,328.4 
	112.5,328.5 111.9,325.6 112.6,319.1 115,314.2 117.7,312.3 118.3,314.7 117.4,315.2 116.2,315.3 114.5,317.3 114,322 114.8,324.9 
	116.8,325.7 119.4,323.2 124.3,321 124.8,321.5 121.6,323.7 120.9,324.8 121.9,324.7 121.9,325.7 123.7,325.7 125.2,324.8 
	125.7,324.7 125,326.5 127.5,326.7 128.4,325.5 129.5,325.5 130.7,326.8 131.6,329.6 132.6,329.9 133.1,328.5 132.1,326.2 
	132.5,325.5 135.5,325.8 137.6,327 138.7,324.9 140.8,323 141.9,323.2 142.5,322.5 150.8,327.1 151,328.5 159.1,326.8 160.8,327.7 
	162.6,327.2 163.5,328.2 163.1,329.5 162.3,330.1 161.9,330.8 160.8,331.4 160.6,332 161.6,332.3 162.6,332 163.1,333.3 
	164.3,333.4 165.4,333.2 167.9,333.3 169,332.9 169.7,329.7 171.3,327.2 172.8,329.7 171.8,330.7 173.6,331.2 173.5,333.9 
	172.6,334.6 172.8,335.6 174.3,335 176.4,336.6 178.6,335.2 176.7,333.6 176.5,332.2 177.9,331.3 178.6,330.1 183.2,331.3 
	187.2,330.8 188.6,331 187.9,333.4 190.8,333 193.1,333.7 191,335.1 191.4,336 193.7,335.5 193.6,336.9 192.6,338.7 193.4,339.4 
	195.1,344.4 196.3,344.4 196.7,343.4 195.8,338.3 201.2,337.5 202,338.4 202,339.7 203.6,341.2 199.5,352.2 199.7,352.9 201,352.7 
	202.1,351.3 202.4,351.9 202,353.2 202.3,354.1 203.6,352.6 205.5,347.7 207.1,346.8 208.7,346.7 209.3,346.2 207.4,345 
	208.4,343.1 207.9,341.4 210.6,342.1 210.9,344.4 213.2,343.6 214.4,343.9 213.8,345.2 214.9,345.4 215.1,346 216,345.1 
	217.2,346.2 217.1,348.7 215,350.5 215,351.4 216,351.4 216.4,351.5 217.5,351.1 222.6,352.7 222,349.3 223.1,347.6 224.1,347.4 
	227.5,348.8 229.4,350.6 230.4,350.7 232.7,352.4 231.5,350.2 233.4,348.9 231.5,345.3 225.9,343.9 221.7,340.3 216.2,340.4 
	212.4,339.3 203.8,332.4 169,319.7 165.2,320.3 142.9,310.9 134.3,300.2 132.9,295.9 135.4,291.8 134,288.7 123.1,281 120.2,281.6 
	114.2,289.3 91.3,273.9 "/>
<polygon className="st3" points="44.6,239.1 44.7,240.8 44.7,239.3 "/>
<polygon className="st3" points="75.3,310 74.3,312 76.2,309 "/>
<polygon className="st2" points="269.8,383.6 271.7,386.6 271.4,389.7 268.6,389.3 266,387.8 259.7,389.9 267.5,392 266.8,394.1 
	265.9,395.1 266.8,396.2 266.8,399 267.3,399.4 269.8,394.9 273,395.1 271.4,402.9 273,405.8 274.5,405 274.8,402.2 277.2,397.6 
	277.9,397.7 282.5,416.9 283.9,418.7 284.1,422.7 285.3,425.3 284.1,436.3 285.5,438.8 284.1,446.3 285.5,450.4 292,454.9 
	292.3,458.1 289.9,461.4 283.6,477.6 281,480 279.8,484.8 277.4,487.7 274.9,489.2 271.5,489.8 265.5,489.1 263.9,489.9 
	263.3,494.2 259.1,496.6 259.3,497.6 261.7,497.6 264.1,502.8 266.7,503.8 268.4,503.6 269.6,504.3 272.1,504.3 276.1,501.9 
	281.5,501.6 285.8,499.7 289.7,499.3 304.1,493.2 303.8,487.9 308.9,479.8 311.1,479.2 316.1,482.9 320.9,489.9 321.2,493 
	319.7,496.3 315.6,500 312.3,505.1 311.6,509.6 302.9,522.4 299.8,524.6 299.7,528.7 302.1,528.4 307.4,525 306.2,529.8 
	309.6,527.2 319.7,513.5 324.6,511 328.2,510.8 331.1,512.7 333.9,520.4 331.8,529.8 320.5,538.7 316.6,539.7 315.9,542.6 
	316.8,546.2 339.2,540.4 340.4,539.2 339.5,537.1 341,535.2 342,535 342,360.8 271.8,382.9 "/>
<path className="st4" d="M17.8,643.9c-2.3-21.6-3.4-43.4-3.4-65.1c0-234.7,131-438.7,323.9-543"/>
<g>
	<path className="st5" d="M163.9,206.5l-3.2,3.2l1.3,2.7l-1,1.1l-4.9-10.5l0.9-0.9l10.6,4.6l-1,1L163.9,206.5z M160.2,208.6l2.6-2.6
		l-4.9-2.2L160.2,208.6z"/>
	<path className="st5" d="M174.5,199.7l-1,1l-9.8-1.9l6,5.8l-1,1l-7.7-7.6l1-1l9.8,1.9l-6-5.8l1-1L174.5,199.7z"/>
	<path className="st5" d="M174.4,185.9l-2.4,2.5l6.9,6.7l-1,1l-6.9-6.7l-2.4,2.5l-0.8-0.8l5.8-6L174.4,185.9z"/>
	<path className="st5" d="M184.2,185.7l-3.2,3.2l1.3,2.7l-1,1.1l-4.9-10.5l0.9-0.9l10.6,4.6l-1,1L184.2,185.7z M180.5,187.8l2.6-2.6
		l-4.9-2.2L180.5,187.8z"/>
	<path className="st5" d="M188.6,179l-1.8,1.8l3.1,3.1l-1,1l-7.7-7.6l2.5-2.6c0.7-0.8,1.6-1.3,2.6-1.4c0.9-0.1,1.8,0.3,2.4,1
		c0.5,0.4,0.8,1,0.9,1.6c0.1,0.6,0,1.3-0.3,1.9l5.1,1.4l0.1,0.1l-1.1,1.1L188.6,179z M186,180l1.5-1.6c0.5-0.4,0.7-1,0.8-1.6
		c0-0.6-0.2-1.1-0.6-1.5c-0.4-0.4-0.9-0.7-1.5-0.6c-0.6,0.1-1.2,0.4-1.6,0.9l-1.5,1.6L186,180z"/>
	<path className="st5" d="M198.6,170.1c0.7,0.8,1,1.7,1,2.8c-0.1,1-0.6,2-1.3,2.7c-0.8,0.9-1.9,1.3-3.1,1.2c-1.3-0.1-2.5-0.8-3.4-1.7
		l-0.7-0.7c-0.7-0.6-1.2-1.4-1.5-2.2c-0.3-0.7-0.4-1.5-0.2-2.3c0.2-0.8,0.6-1.5,1.2-2.1c0.7-0.8,1.6-1.2,2.6-1.3c1,0,2,0.3,2.8,1
		l-1,1c-0.5-0.5-1.2-0.7-1.9-0.7c-0.6,0.1-1.2,0.4-1.6,0.8c-0.6,0.6-0.9,1.4-0.8,2.2c0.2,1,0.7,1.9,1.5,2.6l0.8,0.7
		c0.7,0.7,1.6,1.2,2.5,1.4c0.8,0.1,1.6-0.1,2.2-0.7c0.5-0.4,0.8-1,0.9-1.7c0-0.7-0.3-1.4-0.8-1.9L198.6,170.1z"/>
	<path className="st5" d="M200.6,159.1l-2.4,2.5l6.9,6.7l-1,1l-6.9-6.7l-2.4,2.5l-0.8-0.8l5.8-6L200.6,159.1z"/>
	<path className="st5" d="M209.7,163.6l-1,1l-7.7-7.6l1-1L209.7,163.6z"/>
	<path className="st5" d="M214.6,153.7c0.7,0.8,1,1.7,1,2.8c-0.1,1-0.6,2-1.3,2.7c-0.8,0.9-1.9,1.3-3.1,1.2c-1.3-0.1-2.5-0.8-3.4-1.7
		l-0.7-0.7c-0.7-0.6-1.2-1.4-1.5-2.2c-0.3-0.7-0.4-1.5-0.2-2.3c0.2-0.8,0.6-1.5,1.2-2.1c0.7-0.8,1.6-1.2,2.6-1.3c1,0,2,0.3,2.8,1
		l-1,1c-0.5-0.5-1.2-0.7-1.9-0.7c-0.6,0.1-1.2,0.4-1.6,0.8c-0.6,0.6-0.9,1.4-0.8,2.2c0.2,1,0.7,1.9,1.5,2.6l0.8,0.7
		c0.7,0.7,1.6,1.2,2.5,1.4c0.8,0.1,1.6-0.1,2.2-0.7c0.5-0.4,0.8-1,0.9-1.7c0-0.7-0.3-1.4-0.8-1.9L214.6,153.7z"/>
	<path className="st5" d="M224.2,143.9c0.7,0.8,1,1.7,1,2.8c-0.1,1-0.6,2-1.3,2.7c-0.8,0.9-1.9,1.3-3.1,1.2c-1.3-0.1-2.5-0.8-3.4-1.7
		l-0.7-0.7c-0.7-0.6-1.2-1.4-1.5-2.2c-0.3-0.7-0.4-1.5-0.2-2.3c0.2-0.8,0.6-1.5,1.2-2.1c0.7-0.8,1.6-1.2,2.6-1.3c1,0,2,0.3,2.8,1
		l-1,1c-0.5-0.5-1.2-0.7-1.9-0.7c-0.6,0.1-1.2,0.4-1.6,0.8c-0.6,0.6-0.9,1.4-0.8,2.2c0.2,1,0.7,1.9,1.5,2.6l0.8,0.7
		c0.7,0.7,1.6,1.2,2.5,1.4c0.8,0.1,1.6-0.1,2.2-0.7c0.5-0.4,0.8-1,0.9-1.7c0-0.7-0.3-1.4-0.8-1.9L224.2,143.9z"/>
	<path className="st5" d="M229,143.8l-1,1l-7.7-7.6l1-1L229,143.8z"/>
	<path className="st5" d="M230.5,136l-1.8,1.8l3.1,3.1l-1,1l-7.7-7.6l2.5-2.6c0.7-0.8,1.6-1.3,2.6-1.4c0.9-0.1,1.8,0.3,2.4,1
		c0.5,0.4,0.8,1,0.9,1.6c0.1,0.6,0,1.3-0.3,1.9l5.1,1.4l0.1,0.1l-1.1,1.1L230.5,136z M227.9,137l1.5-1.6c0.5-0.4,0.7-1,0.8-1.6
		c0-0.6-0.2-1.1-0.6-1.5c-0.4-0.4-0.9-0.7-1.5-0.6c-0.6,0.1-1.2,0.4-1.6,0.9l-1.5,1.6L227.9,137z"/>
	<path className="st5" d="M240.5,127.1c0.7,0.8,1,1.7,1,2.8c-0.1,1-0.6,2-1.3,2.7c-0.8,0.9-1.9,1.3-3.1,1.2c-1.3-0.1-2.5-0.8-3.4-1.7
		l-0.7-0.7c-0.7-0.6-1.2-1.4-1.5-2.2c-0.3-0.7-0.4-1.5-0.2-2.3c0.2-0.8,0.6-1.5,1.2-2.1c0.7-0.8,1.6-1.2,2.6-1.3c1,0,2,0.3,2.8,1
		l-1,1c-0.5-0.5-1.2-0.7-1.9-0.7c-0.6,0.1-1.2,0.4-1.6,0.8c-0.6,0.6-0.9,1.4-0.8,2.2c0.2,1,0.7,1.9,1.5,2.6l0.8,0.7
		c0.7,0.7,1.6,1.2,2.5,1.4c0.8,0.1,1.6-0.1,2.2-0.7c0.5-0.4,0.8-1,0.9-1.7c0-0.7-0.3-1.4-0.8-1.9L240.5,127.1z"/>
	<path className="st5" d="M244.5,126.3l3.6-3.7l0.8,0.8l-4.6,4.7l-7.7-7.6l1-1L244.5,126.3z"/>
	<path className="st5" d="M250.7,114.4l-3.3,3.4l2.7,2.7l3.8-3.9l0.8,0.8l-4.8,4.9l-7.7-7.6l4.7-4.9l0.8,0.8l-3.8,3.8l2.5,2.4l3.3-3.4
		L250.7,114.4z"/>
</g>
<path className="st0" d="M5.4,231.7c-0.4,0.7-0.6,1.5-0.5,2.4c0.7-0.1,1.4,0.1,2,0.1c0-0.7,0.5-1.7-0.1-2.3c-0.3-0.4-1.2-0.6-1.7-0.8
	c0,0.3,0.1,0.5,0.1,0.8"/>
<path className="st0" d="M16.6,229.8c-0.6-0.1-1.2,0-1.8,0.1c-0.3,0.8-0.4,1.6-0.3,2.4c0.8-0.1,1.2,0.6,2.1,0.3c0.7-0.2,1-0.9,0.9-1.6
	c-0.5-0.1-2.6,1.5-1.9-0.4c0.5,0,1,0,1.5,0c0-0.4-0.5-0.6-0.7-0.8"/>
<rect x="224.6" y="233.4" transform="matrix(0.703 -0.7112 0.7112 0.703 -99.4034 229.2696)" className="st6" width="0.5" height="0.5"/>
<path className="st0" d="M222.6,29.8L222.6,29.8l-2.7-0.3v0c0,0-0.1,0-0.1,0c-0.5,0-0.8,0.4-0.8,0.8c0,0.4,0.3,0.8,0.7,0.8v0l2.7,0.3v0
	c0,0,0.1,0,0.1,0c0.5,0,0.8-0.4,0.8-0.8C223.4,30.2,223.1,29.9,222.6,29.8z M220.2,33.2L220.2,33.2L220.2,33.2
	C220.2,33.2,220.2,33.1,220.2,33.2l-1.1-0.5l0,0c-0.1-0.1-0.2-0.1-0.4-0.1c-0.3,0-0.6,0.2-0.7,0.5l0,0l0,0c0,0,0,0,0,0l-0.8,1.6l0,0
	c-0.1,0.1-0.1,0.2-0.1,0.4c0,0.5,0.4,0.8,0.8,0.8c0.1,0,0.3,0,0.4-0.1l0,0l1.9-1.1l0,0c0.4-0.2,0.5-0.7,0.3-1.1
	C220.5,33.4,220.4,33.3,220.2,33.2L220.2,33.2z M211.7,26.3c0-0.3-0.2-0.6-0.5-0.7l0,0l-1.6-0.8l0,0c-0.3-0.2-0.7-0.1-1,0.2
	l-0.8,0.8c-0.3,0.3-0.3,0.8,0,1.2c0.2,0.2,0.4,0.2,0.6,0.2h2.4C211.3,27.1,211.7,26.8,211.7,26.3z M209.1,9.4
	c0.3-0.1,0.5-0.4,0.5-0.7c0-0.5-0.4-0.8-0.8-0.8c0,0,0,0-0.1,0l0,0l-3,0.3l0,0c-0.4,0-0.8,0.4-0.8,0.8c0,0.3,0.2,0.7,0.5,0.8
	l1.3,0.5l0,0c0.2,0.1,0.5,0.1,0.7,0l0,0L209.1,9.4L209.1,9.4z M198.3,6l-2.2-1.4l0,0c-0.4-0.2-0.8-0.2-1.1,0.2c0,0,0,0,0,0.1l0,0
	l-0.8,1.4l0,0c-0.2,0.4-0.1,0.9,0.2,1.1c0.1,0.1,0.3,0.1,0.5,0.1h3c0.5,0,0.8-0.4,0.8-0.8C198.7,6.4,198.5,6.2,198.3,6L198.3,6z
	 M196.5,2.9c0-0.2-0.1-0.4-0.2-0.5l0,0L195.2,1l0,0c-0.3-0.4-0.8-0.4-1.1-0.2c0,0,0,0-0.1,0l0,0l-1.6,1.4l0,0
	c-0.2,0.2-0.3,0.4-0.3,0.6c0,0.5,0.4,0.8,0.8,0.8c0,0,0,0,0,0h2.7C196.1,3.7,196.5,3.4,196.5,2.9C196.5,2.9,196.5,2.9,196.5,2.9
	L196.5,2.9z M216.3,40.5L216.3,40.5l-2.4-0.8l0,0c-0.4-0.1-0.8,0-0.9,0.3l0,0l-0.5,0.8l0,0c-0.1,0.1-0.1,0.3-0.1,0.4
	c0,0.5,0.4,0.8,0.8,0.8h3c0.5,0,0.8-0.4,0.8-0.8C216.9,40.9,216.6,40.6,216.3,40.5L216.3,40.5z M211.7,45.3L211.7,45.3
	c-0.2-0.2-0.4-0.2-0.6-0.2c-0.2,0-0.4,0.1-0.6,0.2l-1.3,1.4c-0.2,0.2-0.3,0.4-0.3,0.6c0,0.5,0.4,0.8,0.8,0.8h2.7
	c0.5,0,0.8-0.4,0.8-0.8c0-0.2-0.1-0.4-0.2-0.6L211.7,45.3z"/>
<path className="st0" d="M98.1,35.8c0-0.3-0.2-0.6-0.4-0.7l0,0l-3.5-1.9l0,0c0,0-0.1,0-0.1,0v0l-2.7-1.1l0,0l0,0v0c0,0,0,0,0,0l0,0
	L88,30.9l0,0c0,0-0.1,0-0.1,0l0,0l-3.3-0.5l0,0c0,0-0.1,0-0.1,0c-0.4,0-0.7,0.3-0.8,0.6l0,0L83,33.5l-1.5,0.3v-2.3
	c0-0.5-0.4-0.8-0.8-0.8c-0.1,0-0.2,0-0.4,0.1v0L77,32.3h-2.8c-0.2,0-0.3,0.1-0.5,0.2l0,0l-3.5,2.4l-3.2,1.3l0,0c0,0-0.1,0-0.1,0l0,0
	l-3,1.6l0,0c-0.1,0.1-0.2,0.1-0.2,0.2l0,0l-2.2,2.7l0,0c-0.1,0.1-0.2,0.3-0.2,0.5c0,0.5,0.4,0.8,0.8,0.8c0.1,0,0.2,0,0.2,0l0,0
	l3.8-1.1l0,0c0,0,0,0,0,0l0,0l3.3-1.1l0,0c0.1,0,0.1-0.1,0.2-0.1l0,0l1.7-1.1l-0.3,2l0,0c0,0,0,0.1,0,0.1c0,0.5,0.4,0.8,0.8,0.8
	c0.2,0,0.4-0.1,0.6-0.2l0,0l0,0l0,0l2.7-2.4l2.6,0.5l0,0c0,0,0.1,0,0.1,0v0l0.1,0l0,0h0l3.2,0.3v0c0,0,0,0,0.1,0
	c0.1,0,0.2,0,0.3-0.1l0,0l4.1-1.5l3.5,1.2l2.9,1.6l0,0c0.1,0.1,0.2,0.1,0.3,0.1v0l0,0c0,0,0,0,0,0l0,0l3.2,0.3v0c0,0,0,0,0.1,0
	c0.3,0,0.5-0.1,0.7-0.4l0,0l1.4-1.9l0,0c0.1-0.1,0.2-0.3,0.2-0.5c0-0.5-0.4-0.8-0.8-0.8c0,0,0,0,0,0h-0.8l1.6-1.2l0,0
	C98,36.3,98.1,36.1,98.1,35.8z"/>

</SVGMap>
</div>
    )
}

export default AntarcticPeninsulaMap;