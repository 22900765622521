import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import AntarcticaMap from "../components/AntarcticaMap/AntarcticaMap"


const IndexPage = () => (
  <Layout pageTitle="Antarctica">
    <SEO title="The Antarctic Continent" />
    <div style={{
      width: '100%'
    }}>
      {/* <h1>WTF</h1> */}
    <AntarcticaMap />
    </div>
  </Layout>
)

export default IndexPage
